import Model, { fields } from '@thinknimble/tn-models'

export default class Company extends Model {
  static id = new fields.CharField({ readOnly: true })
  static name = new fields.CharField({ readOnly: true })
  static sfId = new fields.CharField({ readOnly: true })
  static isPortfolioCompany = new fields.BooleanField({ readOnly: true })
  static portfolioPointContactName = new fields.CharField({ readOnly: true })
  static numberOfEmployees = new fields.IntegerField({ readOnly: true })
}
