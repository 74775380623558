import { createRouter, createWebHistory } from 'vue-router'
import {
  homeRouteGuard,
  redirectIfLoggedIn,
  requireAuth,
  requireIsAdmin,
  requireIsAdminOrFriendly,
  requireIsFounderForList,
  requireIsFounder,
} from '@/services/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: [requireAuth, homeRouteGuard],
    // this route redirects users according to their permission group.
  },
  {
    path: '/login/auth/:uid/:token',
    name: 'LoginLinkAuth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loginLinkAuth" */ '../views/LoginLinkAuth.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: [redirectIfLoggedIn('Home')],
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/customer-lists/:id',
    name: 'AdminCustomerListShow',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "adminCustomerListShow" */ '../views/customerLists/AdminCustomerListShow.vue'
      ),
  },
  {
    path: '/customer-lists',
    name: 'AdminCustomerListIndex',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "adminCustomerListIndex" */ '../views/customerLists/AdminCustomerListIndex.vue'
      ),
  },
  {
    path: '/founder-customer-lists',
    name: 'FounderCustomerListIndex',
    beforeEnter: [requireAuth, requireIsFounder],
    component: () =>
      import(
        /* webpackChunkName: "founderCustomerListIndex" */ '../views/customerLists/FounderCustomerListIndex.vue'
      ),
  },
  {
    path: '/founder-customer-lists/:id',
    name: 'FounderCustomerListShow',
    beforeEnter: [requireAuth, requireIsFounderForList],
    component: () =>
      import(
        /* webpackChunkName: "founderCustomerListShow" */ '../views/customerLists/FounderCustomerListShow.vue'
      ),
  },
  {
    path: '/intro-requests',
    name: 'FriendlyIntroRequests',
    beforeEnter: [requireAuth, requireIsAdminOrFriendly],
    component: () =>
      import(
        /* webpackChunkName: "friendlyIntroRequests" */ '../views/opportunities/FriendlyIntroRequests.vue'
      ),
  },
  {
    path: '/outstanding-intro-requests',
    name: 'OutstandingIntroRequests',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "outstandingIntroRequests" */ '../views/admin/OutstandingIntroRequests.vue'
      ),
  },
  {
    path: '/email-templates/new',
    name: 'OpportunityEmailTemplateNew',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "opportunityEmailTemplateNew" */ '../views/opportunityEmailTemplates/OpportunityEmailTemplateNew.vue'
      ),
  },
  {
    path: '/email-templates/:id/edit',
    name: 'OpportunityEmailTemplateEdit',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "opportunityEmailTemplateEdit" */ '../views/opportunityEmailTemplates/OpportunityEmailTemplateEdit.vue'
      ),
  },
  {
    path: '/email-templates/:id',
    name: 'OpportunityEmailTemplateShow',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "opportunityEmailTemplateShow" */ '../views/opportunityEmailTemplates/OpportunityEmailTemplateShow.vue'
      ),
  },
  {
    path: '/email-templates',
    name: 'OpportunityEmailTemplateIndex',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "opportunityEmailTemplateIndex" */ '../views/opportunityEmailTemplates/OpportunityEmailTemplateIndex.vue'
      ),
  },
  {
    path: '/build/customer-lists',
    name: 'InternalCustomerListIndex',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "internalCustomerListIndex" */ '../views/internalCustomerLists/InternalCustomerListIndex.vue'
      ),
  },
  {
    path: '/build/customer-lists/:id',
    name: 'InternalCustomerListEdit',
    beforeEnter: [requireAuth, requireIsAdmin],
    component: () =>
      import(
        /* webpackChunkName: "internalCustomerListEdit" */ '../views/internalCustomerLists/InternalCustomerListEdit.vue'
      ),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
})

export default router
