import { toast as t } from 'vue3-toastify'

export const toast = (msg, type = 'default') =>
  t(msg, {
    position: t.POSITION.TOP_RIGHT,
    type,
  })

export const errorToast = (msg) => toast(msg, 'error')

export const infoToast = (msg) => toast(msg, 'info')

export const successToast = (msg) => toast(msg, 'success')
