import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const CONTACTS_ENDPOINT = '/salesforce/gtm/contacts/'

export default class ContactAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = CONTACTS_ENDPOINT

  static FILTERS_MAP = {
    // NOTE: While we are being explicit with the Pagination and Sorting params, the inherited object includes them already
    ...ContactAPI.FILTERS_MAP,
    // Pagination
    page: ApiFilter.create({ key: 'page' }),
    pageSize: ApiFilter.create({ key: 'page_size' }),
    // Sorting
    ordering: ApiFilter.create({ key: 'ordering' }),
    // filters
    exclude: ApiFilter.create({ key: 'exclude' }),
    name: ApiFilter.create({ key: 'name' }),
    nameContains: ApiFilter.create({ key: 'name_contains' }),
    title: ApiFilter.create({ key: 'title' }),
    companyName: ApiFilter.create({ key: 'company_name' }),
    lastInboundEmail: ApiFilter.create({ key: 'sigparser_last_inbound_email' }),
    gtmListsInLast2Months: ApiFilter.create({ key: 'gtm_lists_in_last_2_months' }),
    location: ApiFilter.create({ key: 'location' }),
    companyEmployeeCountRanges: ApiFilter.create({ key: 'company_employee_count_ranges' }),
  }

  update(id, data) {
    const url = `${CONTACTS_ENDPOINT}${id}/`
    return this.client
      .patch(url, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'ContactAPI.update',
        }),
      )
  }
}
