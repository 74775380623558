<template>
  <div class="font-avenir antialiased text-center">
    <nav v-if="isLoggedIn" class="flex justify-center items-center p-7">
      <img class="h-10 rounded-sm mr-auto" alt="First Round Logo" src="./assets/abbr_logo_2.png" />
      <template v-if="isAdmin">
        <template v-for="link in ADMIN_NAV_LINKS" :key="link.route">
          <router-link :to="{ name: link.route }" class="router">
            {{ link.displayText }}
          </router-link>
          |
        </template>
      </template>
      <template v-else-if="isFriendly">
        <template v-for="link in FRIENDLY_NAV_LINKS" :key="link.route">
          <router-link :to="{ name: link.route }" class="router">
            {{ link.displayText }}
          </router-link>
          |
        </template>
      </template>
      <template v-else-if="isFounder">
        <template v-for="link in FOUNDER_NAV_LINKS" :key="link.route">
          <router-link :to="{ name: link.route }" class="router">
            {{ link.displayText }}
          </router-link>
          |
        </template>
      </template>
      <span id="logout" class="router mr-auto" @click="logout()">Logout</span>
    </nav>
    <main>
      <router-view :key="$route.path" />
    </main>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import User from '@/services/users/'

const ADMIN_NAV_LINKS = [
  {
    route: 'AdminCustomerListIndex',
    displayText: 'Customer Lists',
  },
  {
    route: 'FriendlyIntroRequests',
    displayText: 'Intro Requests',
  },
  {
    route: 'OutstandingIntroRequests',
    displayText: 'Outstanding Requests',
  },
  {
    route: 'OpportunityEmailTemplateIndex',
    displayText: 'Email Templates',
  },
  {
    route: 'InternalCustomerListIndex',
    displayText: 'Build Customer Lists',
  },
]

const FRIENDLY_NAV_LINKS = [
  {
    route: 'FriendlyIntroRequests',
    displayText: 'Intro Requests',
  },
]

const FOUNDER_NAV_LINKS = [
  {
    route: 'FounderCustomerListIndex',
    displayText: 'Founder Customer Lists',
  },
]

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const router = useRouter()

    async function handleLogoutSuccess() {
      await store.dispatch('setUser', null)
      router.push({ name: 'Home' })
    }

    async function logout() {
      User.api.logout().finally(handleLogoutSuccess)
    }

    return {
      logout,
      isLoggedIn: computed(() => store.getters.isLoggedIn),
      isAdmin: computed(() => store.getters.user?.isAdmin),
      isFriendly: computed(() => store.getters.user?.isFriendly),
      isFounder: computed(() => store.getters.user?.isFounder),
      ADMIN_NAV_LINKS,
      FRIENDLY_NAV_LINKS,
      FOUNDER_NAV_LINKS,
    }
  },
}
</script>
