import Model, { fields } from '@thinknimble/tn-models'
import Contact from '../contacts'
import UserAPI from './api'

export default class User extends Model {
  static api = UserAPI.create(User)

  static id = new fields.CharField({ readOnly: true })
  static firstName = new fields.CharField()
  static lastName = new fields.CharField()
  static email = new fields.CharField()
  static fullName = new fields.CharField({ readOnly: true })
  static token = new fields.CharField()
  static isAdmin = new fields.BooleanField({ readOnly: true })
  static isFounder = new fields.BooleanField({ readOnly: true })
  static isFriendly = new fields.BooleanField({ readOnly: true })
  static isFirstRoundPartner = new fields.BooleanField({ readOnly: true })
  static salesforceContact = new fields.ModelField({ ModelClass: Contact })
}
