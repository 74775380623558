import { ModelAPI } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const LOGIN_REQUEST_ENDPOINT = '/login/request/'
const LOGIN_WITH_TOKEN_ENDPOINT = '/login/confirm/'
const LOGOUT_ENDPOINT = '/logout/'
const USERS_ENDPOINT = '/users/'
const ALL_FRIENDLY_USERS_ENDPOINT = '/users/friendlies/'
const PASSWORD_RESET_ENDPOINT = '/password/reset/confirm/'
const CHECK_LIST_PERMISSIONS_ENDPOINT = '/auth/check-list-permissions/'
const CHECK_EMAIL_TEMPLATE_PERMISSIONS_ENDPOINT = '/auth/check-email-template-permissions/'

export default class UserAPI extends ModelAPI {
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FITERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  get client() {
    return AxiosClient
  }

  static ENDPOINT = USERS_ENDPOINT

  checkListPermissionsForUser({ user_id, list_id }) {
    const data = { user_id, list_id }
    return this.client
      .post(CHECK_LIST_PERMISSIONS_ENDPOINT, data)
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'UserAPI.checkListPermissionsForUser',
        }),
      )
  }

  checkEmailTemplatePermissionsForUser({ userId, opportunityEmailTemplateId }) {
    const data = { userId, opportunityEmailTemplateId }
    return this.client
      .post(CHECK_EMAIL_TEMPLATE_PERMISSIONS_ENDPOINT, this.cls.toAPI(data))
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'UserAPI.checkEmailTemplatePermissionsForUser',
        }),
      )
  }

  requestLoginLink(email) {
    const url = `${LOGIN_REQUEST_ENDPOINT}?email=${email.toLowerCase()}`
    return this.client.get(url).catch(
      apiErrorHandler({
        apiName: 'UserAPI.requestLoginLink',
      }),
    )
  }

  loginWithToken({ uid, token }) {
    const data = { uid, token }
    return this.client
      .post(LOGIN_WITH_TOKEN_ENDPOINT, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'UserAPI.loginWithToken', enable400Alert: true }))
  }

  logout() {
    return this.client.post(LOGOUT_ENDPOINT).catch(apiErrorHandler({ apiName: 'UserAPI.logout' }))
  }

  resetPassword({ uid, token, password }) {
    const data = { uid, token, password }
    return this.client
      .post(PASSWORD_RESET_ENDPOINT, data)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'UserAPI.resetPassword' }))
  }

  listFriendlies() {
    return this.client
      .get(ALL_FRIENDLY_USERS_ENDPOINT)
      .then((response) => response.data.map((f) => this.cls.fromAPI(f)))
      .catch(apiErrorHandler({ apiName: 'UserAPI.listFriendlies' }))
  }
}
