import store from '@/store'
import User from '@/services/users'

/**
 * Route Guard.
 * All logged in users can access the route.
 * If not logged in, a user will be redirected to the login page.
 */
export function requireAuth(to, from, next) {
  if (!store.getters.isLoggedIn) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

/**
 * RETURNS Route Guard.
 * If logged in, a user will be redirected to the route by name of args.redirectTo.
 */
export const redirectIfLoggedIn = (redirectTo) => (to, from, next) => {
  if (store.getters.isLoggedIn) next({ name: redirectTo })
  else next()
}

/**
 * Route Guard for root endpoint.
 * Each user type is redirected to an appropriate first page.
 */
export function homeRouteGuard(to, from, next) {
  if (store.getters.user.isAdmin) return next({ name: 'AdminCustomerListIndex' })
  if (store.getters.user.isFriendly) return next({ name: 'FriendlyIntroRequests' })
  if (store.getters.user.isFounder) return next({ name: 'FounderCustomerListIndex' })

  // a user may be missing a permission group by mistake
  next({ name: 'PageNotFound' })
}

/**
 * Route Guard.
 * Admin users can access the route.
 * If not admin, a user will be redirected to the 404 page.
 */
export function requireIsAdmin(to, from, next) {
  if (store.getters.user.isAdmin) next()
  else next({ name: 'PageNotFound' })
}

/**
 * Route Guard.
 * Friendly users can access the route.
 * If not friendly, a user will be redirected to the 404 page.
 */
export function requireIsFriendly(to, from, next) {
  if (store.getters.user.isFriendly) next()
  else next({ name: 'PageNotFound' })
}

/**
 * Route Guard.
 * Friendly and Admin users can access the route.
 * If not friendly, a user will be redirected to the 404 page.
 */
export function requireIsAdminOrFriendly(to, from, next) {
  if (store.getters.user.isFriendly || store.getters.user.isAdmin) next()
  else next({ name: 'PageNotFound' })
}

/**
 * Route Guard.
 * Founder users can access the route.
 * If not friendly, a user will be redirected to the 404 page.
 */
export function requireIsFounder(to, from, next) {
  if (store.getters.user.isFounder) next()
  else next({ name: 'PageNotFound' })
}

/**
 * Route Guard. Only Founders of a list or Admins can access the route.
 * If not an assigned founder or admin, a user will be redirected to the 404 page.
 */
export async function requireIsFounderForList(to, from, next) {
  const user_id = store.getters.user.id
  const list_id = to.params.id
  try {
    const response = await User.api.checkListPermissionsForUser({ user_id, list_id })
    response['authorized'] ? next() : next({ name: 'PageNotFound' })
  } catch {
    next({ name: 'PageNotFound' })
  }
}

/**
 * Route Guard.
 * Friendly and Admin users can access.
 * If is Friendly, must be the author of the Email Template.
 * If is Admin, can access regardless of authorship.
 */
export async function requireIsAdminOrAuthorOfEmailTemplate(to, from, next) {
  const userId = store.getters.user.id
  const opportunityEmailTemplateId = to.params.id
  try {
    const response = await User.api.checkEmailTemplatePermissionsForUser({
      userId,
      opportunityEmailTemplateId,
    })
    response['authorized'] ? next() : next({ name: 'PageNotFound' })
  } catch {
    next({ name: 'PageNotFound' })
  }
}
