export const LOCATION_OPTIONS = [
  'Austin, TX',
  'Bay Area/East Bay, CA',
  'Bay Area/Peninsula, CA',
  'Bay Area/SF, CA',
  'Boston, MA',
  'Boulder, CO',
  'Chicago, IL',
  'Dallas, TX',
  'Denver, CO',
  'International',
  'Los Angeles, CA',
  'New York Metro Area',
  'Other',
  'Other US',
  'Philadelphia, PA',
  'Portland, OR',
  'San Diego, CA',
  'Seattle, WA',
  'Southern California',
  'Washington, DC',
]

export const COMPANY_EMPLOYEE_COUNT_OPTIONS = [
  { order: 0, value: '1-10' },
  {
    order: 1,
    value: '11-50',
  },
  {
    order: 2,
    value: '51-200',
  },
  {
    order: 3,
    value: '201-500',
  },
  {
    order: 4,
    value: '501-1000',
  },
  {
    order: 5,
    value: '1001-5000',
  },
  {
    order: 6,
    value: '5001-10000',
  },
  {
    order: 7,
    value: '10001+',
  },
]

export default { LOCATION_OPTIONS, COMPANY_EMPLOYEE_COUNT_OPTIONS }
