import moment from 'moment'
import Model, { fields } from '@thinknimble/tn-models'
import Company from '../companies/models'
import ContactAPI from './api'

export default class Contact extends Model {
  static api = ContactAPI.create(Contact)
  static sigparserLastOutboundEmail = new fields.CharField()
  static sigparserLastInboundEmail = new fields.CharField()
  static closestInternalEmail = new fields.CharField()
  static warmth = new fields.CharField()
  static company = new fields.ModelField({ ModelClass: Company })
  static id = new fields.CharField({ readOnly: true })
  static email = new fields.CharField()
  static name = new fields.CharField()
  static linkedinProfile = new fields.CharField()
  static mailboxes = new fields.CharField()
  static sfId = new fields.CharField({ readOnly: true })
  static title = new fields.CharField()
  static gtmListsInLast2Months = new fields.IntegerField()
  static location = new fields.CharField()

  get nameWithCompany() {
    return `${this.name} @ ${this.company?.name || 'N/A'}`
  }

  get lastOutboundMoment() {
    if (this.sigparserLastOutboundEmail === null || this.sigparserLastOutboundEmail === '')
      return null
    return moment(this.sigparserLastOutboundEmail)
  }

  get lastInboundMoment() {
    if (this.sigparserLastInboundEmail === null || this.sigparserLastInboundEmail === '')
      return null
    return moment(this.sigparserLastInboundEmail)
  }
}
